<template>
    <div class="EventType">
        <slot name="ProgressBar" />
        <page-header :title="`필요한 분야를 알려주세요.`"></page-header>

        <div class="ui-border-line ui-h-0"></div>

        <template v-for="(row, key) in eventType">
            <div :key="key">
                <div class="EventType-category">{{row.category}}</div>
                <div>
                    <tag-box>
                        <li
                            v-for="(row, key) in row.eventTypes"
                            :key="key"
                            @click="() => formData.eventType = row"
                            :class="{ on: formData.eventType === row }"
                        >{{row}}</li>
                        <li
                            @click="() => showEtcModal(row.category)"
                            :class="{ on: formData.eventType === formData.etc && modalData.inputCategory === row.category }"
                        >직접입력</li>
                    </tag-box>
                </div>
            </div>
        </template>

        <modal
            ref="EtcModal"
            title="직접 입력해주세요"
            class="etc"
            confirm="등록"
            :confirmCb="modal => {
                formData.etc = modalData.etc
                formData.eventType = modalData.etc
                modalData.inputCategory = modalData.currentCategory
                modalData.currentCategory = null
                modal.hide()
            }"
        >
            <div slot="body">
                <div class="ui-inputbox etc">
                    <input type="text" v-model="modalData.etc" placeholder="직접 입력해주세요." />
                </div>
            </div>
        </modal>

        <div class="ui-border-line ui-h-0 ui-mb-6"></div>

        <footer-box submitText="다음" :submitCb="() => nextStep()" :submitDisabled="!validate"></footer-box>
    </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader'
import TagBox from '@/components/common/TagBox'
import FooterBox from '@/components/common/FooterBox'
import Modal from '@/components/common/Modal'

export default {
    components: {
        PageHeader,
        TagBox,
        FooterBox,
        Modal,
    },
    data() {
        const postOffer = this.$store.state.postOffer
        const eventType = this.$config.constant.eventType
        const eventTypes = eventType.map(item => item.eventTypes).flat()
        const etc = postOffer.eventType && eventTypes.indexOf(postOffer.eventType) === -1 ? postOffer.eventType : ''

        return {
            eventType,
            formData: {
                eventType: postOffer.eventType,
                etc,
            },
            modalData: {
                currentCategory: null,
                inputCategory: null,
                etc: '',
            },
        }
    },
    computed: {
        step: {
            get() {
                return this.$route.params.step
            },
        },
        postOffer: {
            get() {
                return this.$store.state.postOffer
            },
            set(val) {
                this.$store.commit('setPostOffer', val)
            },
        },
        validate: {
            get() {
                return !this.formData.eventType === false
            },
        },
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            try {
                if (vm.formData.etc) {
                    const pageCache = JSON.parse(sessionStorage.getItem('pageCache:PostOffer_EventType'))
                    for (const key in pageCache) {
                        const val = pageCache[key]
                        vm.$set(vm, key, val)
                    }
                }
            } finally {
                sessionStorage.removeItem('pageCache:PostOffer_EventType')
            }
        })
    },
    beforeRouteLeave (to, from, next) {
        const pageCache = JSON.stringify({ modalData: this.modalData })
        sessionStorage.setItem('pageCache:PostOffer_EventType', pageCache)
        next()
    },
    methods: {
        nextStep() {
            const eventType = this.formData.eventType
            this.postOffer = { eventType }
            this.$parent.nextStep()
        },
        showEtcModal(category) {
            this.modalData.currentCategory = category
            this.modalData.etc = this.modalData.inputCategory === category
                ? this.formData.etc
                : ''
            this.$refs.EtcModal.show()
        },
    },
}
</script>

<style lang="scss" scoped>
.EventType {
    .EventType-category {
        font-size: 20px;
        color: #999;
        padding: 15px 0;
    }
}
</style>
