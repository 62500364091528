var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "EventType" },
    [
      _vm._t("ProgressBar"),
      _c("page-header", { attrs: { title: "필요한 분야를 알려주세요." } }),
      _c("div", { staticClass: "ui-border-line ui-h-0" }),
      _vm._l(_vm.eventType, function(row, key) {
        return [
          _c("div", { key: key }, [
            _c("div", { staticClass: "EventType-category" }, [
              _vm._v(_vm._s(row.category))
            ]),
            _c(
              "div",
              [
                _c(
                  "tag-box",
                  [
                    _vm._l(row.eventTypes, function(row, key) {
                      return _c(
                        "li",
                        {
                          key: key,
                          class: { on: _vm.formData.eventType === row },
                          on: {
                            click: function() {
                              return (_vm.formData.eventType = row)
                            }
                          }
                        },
                        [_vm._v(_vm._s(row))]
                      )
                    }),
                    _c(
                      "li",
                      {
                        class: {
                          on:
                            _vm.formData.eventType === _vm.formData.etc &&
                            _vm.modalData.inputCategory === row.category
                        },
                        on: {
                          click: function() {
                            return _vm.showEtcModal(row.category)
                          }
                        }
                      },
                      [_vm._v("직접입력")]
                    )
                  ],
                  2
                )
              ],
              1
            )
          ])
        ]
      }),
      _c(
        "modal",
        {
          ref: "EtcModal",
          staticClass: "etc",
          attrs: {
            title: "직접 입력해주세요",
            confirm: "등록",
            confirmCb: function(modal) {
              _vm.formData.etc = _vm.modalData.etc
              _vm.formData.eventType = _vm.modalData.etc
              _vm.modalData.inputCategory = _vm.modalData.currentCategory
              _vm.modalData.currentCategory = null
              modal.hide()
            }
          }
        },
        [
          _c("div", { attrs: { slot: "body" }, slot: "body" }, [
            _c("div", { staticClass: "ui-inputbox etc" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.modalData.etc,
                    expression: "modalData.etc"
                  }
                ],
                attrs: { type: "text", placeholder: "직접 입력해주세요." },
                domProps: { value: _vm.modalData.etc },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.modalData, "etc", $event.target.value)
                  }
                }
              })
            ])
          ])
        ]
      ),
      _c("div", { staticClass: "ui-border-line ui-h-0 ui-mb-6" }),
      _c("footer-box", {
        attrs: {
          submitText: "다음",
          submitCb: function() {
            return _vm.nextStep()
          },
          submitDisabled: !_vm.validate
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }